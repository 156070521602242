<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
  <v-tabs
    v-model="tab"
    align-with-title
  >
    <v-tabs-slider color="rgb(0, 161, 183)"></v-tabs-slider>

    <v-tab
      v-for="item in items"
      :key="item"
      :name="item"
    >
      {{ item }}
    </v-tab>
      <v-tabs-items v-model="tab">
      <v-tab-item>
       <base-material-card
      color="primary"
      icon="mdi-chart-pie"
      title="Policies"
      class="px-4 py-3"
    >
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="9"
        >
          <v-row class="d-flex justify-center">
            <v-col
              cols="6"
            >
              <v-select
                v-model="quarter"
                outlined
                :items="quarters"
                label="Select Quarter"
                @change="showSelectedData(year, quarter)"
              ></v-select>
            </v-col>
            <v-col
              cols="6"
            >
              <v-select
                v-model="year"
                outlined
                :items="years"
                label="Select Year"
                @change="showSelectedData(year, quarter)"
              ></v-select>
            </v-col>
          </v-row>
          <v-card class="pa-4 mt-2">
            <v-card-title class="d-flex justify-center">
              {{ quarter }} Policies By Week
            </v-card-title>
            <div id="chart">
              <v-row class="d-inline-flex justify-center ml-4">
                <v-col>
                  <div>{{ quarter }} {{ year }}</div>
                </v-col>
              </v-row>
              <apexchart
                ref="quarterPolicies"
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="9"
        >
          <v-card class="pa-4 mt-2">
            <v-card-title class="d-flex justify-center">
              {{ quarter }} Policies By Type
            </v-card-title>
            <v-row class="d-inline-flex justify-center ml-4">
              <v-col>
                <div>{{ quarter }} {{ year }}</div>
              </v-col>
            </v-row>
            <apexchart
              ref="typeChart"
              type="bar"
              height="350"
              :options="policiesByTypeOptions"
              :series="policiesByTypeSeries"
            ></apexchart>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
    <base-material-card
      color="primary"
      icon="mdi-file-table"
      title="Policies"
      class="px-4 py-3"
    >
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="policyData"
            item-key="name"
            class="elevation-1"
          ></v-data-table>
        </v-col>
      </v-row>
    </base-material-card>
      </v-tab-item>
      <v-tab-item name="reportCa">
       <admin-sales-report />
      </v-tab-item>
    </v-tabs-items>
    </v-tabs>
    <!----------------------------------------POLICY NUMBERS----------------------------------------->
  </v-container>
</template>

<script>
  import moment from 'moment'
  import AdminSalesReport from './AdminSalesReport'
  export default {
    components: {
      AdminSalesReport
    },
    data: () => ({
      tab: null,
      items: ['Policies', 'Reports'],
      headers: [{
                  text: 'Year',
                  value: 'year',
                  align: 'start',
                  sortable: false,
                },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Week 1', value: 'week1' },
                { text: 'Week 2', value: 'week2' },
                { text: 'Week 3', value: 'week3' },
                { text: 'Week 4', value: 'week4' },
                { text: 'Week 5', value: 'week5' },
                { text: 'Week 6', value: 'week6' },
                { text: 'Week 7', value: 'week7' },
                { text: 'Week 8', value: 'week8' },
                { text: 'Week 9', value: 'week9' },
                { text: 'Week 10', value: 'week10' },
                { text: 'Total', value: 'total' }
      ],
      policyData: [{ year: '2022', quarter: 'Q1', week1: 230, week2: 310, week3: 400, week4: 1010, week5: 400, week6: 360, week7: 320, week8: 230, week9: 140, week10: 280, total: '3680' }],
      years: ['2022'],
      year: '',
      currentYear: '',
      quarters: ['Q1', 'Q2', 'Q3', 'Q4'],
      quarter: 'Q1',
      series: [{ data: [] }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50%',
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8', 'Week 9', 'Week 10'],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          }

        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          },
        }
      },
      policiesByTypeSeries: [],
      policiesByTypeOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5', 'Week6', 'Week7', 'Week8', 'Week9', 'Week10'],
          labels: {
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    }),
    computed: {
      computedMinDate () {
        return this.startDate
      },
      computedMaxDate () {
        if (this.startDate) {
          return moment(this.startDate).add(500, 'years').format('YYYY-MM-DD')
        }
        return null
      },
    },
    watch: {
      startDate (val) {
        // this.endDate = moment(val).add(1, 'day').format('YYYY-MM-DD')
      }
    },
    created () {
      this.getYear()
    },
    methods: {
      getYear () {
        const d = new Date()
        let year = d.getFullYear().toString()
        this.year = year
        this.currentYear = year
      },
      showSelectedData (year, quarter) {
        // Return policy summary
        let policyQuaterSummary = this.$store.getters.policyQuarterSummary
        console.log(policyQuaterSummary)
        let quarterlyPolicies = policyQuaterSummary.filter((item) => {
          return item.year === year && item.quarter === quarter
        })
        console.log(quarterlyPolicies)
        let newSeries = quarterlyPolicies[0].data
        this.$refs.quarterPolicies.updateSeries([{ data: newSeries }])
        // Return policies by type
        let policyTypeSummary = this.$store.getters.policyTypeSummary
        let policiesByType = policyTypeSummary.filter((item) => {
          return item.year === year && item.quarter === quarter
        })
        console.log(policiesByType)
        let newTypeSeries = policiesByType
        this.$refs.typeChart.updateSeries(newTypeSeries)
      }
    }
  }
</script>

<style lang="scss" scoped>
.policy-numbers {
  font-size: 36px;
}
.style-card{
  widows: 800px;
  margin: auto;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg{
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: #333!important;
  cursor:not-allowed;
}
</style>
