<template>
  <v-form
    v-model="valid"
    lazy-validation
  >
    <v-dialog
      v-model="errorMessageDialog"
      max-width="700"
      persistent
      class="style-modal"
    >
      <v-card min-height="20vh">
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>{{ errorMessage }}</v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                @click="clearErrorMessage"
              >
                {{ $t('close') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-card class="pa-4 mt-2">
      <v-row>
        <v-col>
          <v-overlay v-if="loading">
            <v-progress-circular
              v-if="loading"
              :size="80"
              color="primary"
              indeterminate
              class="style-loader"
            >
            </v-progress-circular>
          </v-overlay>
        </v-col>
      </v-row>
      <v-card-title class="d-flex justify-center mb-5 admin-report-header">
        Admin Report
      </v-card-title>
      <div class="justify-center">
        <v-row
          class="justify-center"
        >
          <v-col
            cols="11"
            sm="4"
            md="4"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="productReport"
              :rules="{
                required: true
              }"
            >
              <p class="mb-0">
                Report type
              </p>
              <v-select
                v-model="productReport"
                outlined
                :items="products"
                item-text="text"
                item-value="value"
                required
                :error-messages="errors"
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row
          class="justify-center"
        >
          <v-col
            cols="11"
            sm="4"
            md="4"
          >
            <v-dialog
              ref="dialog2"
              v-model="modal2"
              :return-value.sync="startDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <p class="mb-0">
                    Start date
                  </p>
                  <v-text-field
                    v-model="startDate"
                    :error-messages="errors"
                    readonly
                    v-bind="attrs"
                    outlined
                    placeholder="Start Date"
                    v-on="on"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                v-model="startDate"
                scrollable
                width="290"
                :max="computedMaxDate"
              >
                <div class="d-flex justify-space-between">
                  <v-btn
                    text
                    color="primary"
                    width="30"
                    @click="
                      modal2 = false;
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    width="30"
                    color="primary"
                    @click="
                      $refs.dialog2.save(startDate);
                      checkDate(startDate)
                    "
                  >
                    OK
                  </v-btn>
                </div>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row
          class="justify-center"
        >
          <v-col
            cols="11"
            sm="4"
            md="4"
          >
            <v-dialog
              ref="dialog1"
              v-model="modal1"
              :return-value.sync="endDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <p class="mb-0">
                    End date
                  </p>
                  <v-text-field
                    v-model="endDate"
                    :error-messages="errors"
                    readonly
                    v-bind="attrs"
                    outlined
                    placeholder="End Date"
                    v-on="on"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                v-model="endDate"
                scrollable
                width="290"
                :max="computedMaxDate"
                :min="computedMinDate"
              >
                <div class="d-flex justify-space-between">
                  <v-btn
                    text
                    color="primary"
                    width="30"
                    @click="
                      modal1 = false;
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    width="30"
                    color="primary"
                    @click="
                      $refs.dialog1.save(endDate);
                    "
                  >
                    OK
                  </v-btn>
                </div>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col class="text-center">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              :disabled="!productReport"
              @click="downloadCsv"
            >
              Download csv
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-form>
</template>

<script>
  import moment from 'moment'

  export default {
    data: () => ({
      products: [
        {
          text: 'Ca Term Life Sales Report', value: 'caterm'
        },
        {
          text: 'US Travel Sales Report', value: 'usatravel'
        },
        {
          text: 'Knockout Users Report', value: 'knockout'
        },
        {
          text: 'CA Term User Journey Report', value: 'userjourney'
        },
        {
          text: 'CST Partner Report', value: 'cst'
        },
        {
          text: 'GGA Partner Report', value: 'gga'
        },
        {
          text: 'All Partner Report', value: 'all'
        }
      ],
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      modal2: null,
      modal1: null,
      valid: false,
      errorMessageDialog: false,
      productReport: 'caterm'
    }),
    computed: {
      loading () {
        return this.$store.getters.getloading
      },
      computedMinDate () {
        return this.startDate
      },
      computedMaxDate () {
        return moment().format('YYYY-MM-DD')
      },
      errorMessage () {
        return this.$store.getters.getErrors
      }
    },
    watch: {
      errorMessage (val) {
        this.errorMessageDialog = !!val
      },
    },
    mounted () {
      this.clearErrorMessage()
    },
    methods: {
      downloadCsv () {
        const payload = {
          startDate: this.startDate,
          endDate: this.endDate,
          type: this.productReport
        }
        this.$store.dispatch('getReport', payload)
      },
      clearErrorMessage () {
        this.$store.dispatch('clearError')
      },
      checkDate (startDate) {
        if (startDate > this.endDate) {
          this.endDate = moment(startDate).add(0, 'day').format('YYYY-MM-DD')
        }
      }
    }
  }

</script>
<style scoped>
.style-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
}

.style-modal {
  height: 200px;
}
</style>
